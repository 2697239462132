import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchPartner } from '../utils/api/partnerManage/partnerManageApi'

export const fetchSearchPartner = createAsyncThunk('partnerInfo/fetchSearchPartner', async params => {
  const res = await searchPartner(params)
  return res.data
})

function toggleIsOpened(userId, data) {
  return data.map(item => {
    const newItem = { ...item } // 얕은 복사로 새 객체 생성

    if (item.userId === userId) {
      newItem.isOpened = !item.isOpened
    }

    if (item.subUserInfos && item.subUserInfos.length > 0) {
      newItem.subUserInfos = toggleIsOpened(userId, item.subUserInfos)
    }

    return newItem
  })
}

const partnerInfoSlice = createSlice({
  name: 'partnerInfo',
  initialState: {
    partnerManageList: [],
    apiLoading: false,
    selectedUserId: '',
    searchParams: {
      edgeName: null,
      userId: null,
      topUserId: null,
    },

    userMap: {}, // userMap의 역할은 빠른 검색 + 관계 매핑 최적화
  },
  reducers: {
    resetPartnerList: (state, action) => {
      state.partnerManageList = []
      state.selectedUserId = ''
    },
    handleToggle: (state, action) => {
      const { userId } = action.payload

      state.selectedUserId = userId

      state.searchParams = {
        ...state.searchParams,
        topUserId: userId,
      }

      state.partnerManageList = toggleIsOpened(userId, state.partnerManageList)
    },
    onClickSearchHandler: (state, action) => {
      state.partnerManageList = []

      state.searchParams = {
        ...state.searchParams,
        topUserId: null,
        userId: action.payload,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSearchPartner.pending, (state, action) => {
      state.apiLoading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSearchPartner.fulfilled, (state, action) => {
      const { content } = action.payload

      // userMap 갱신 및 세팅
      content.forEach(user => {
        user.subUserInfos = user.subUserInfos || []
        state.userMap[user.userId] = user
      })

      // 부모-자식 관계 설정 (중복 방지)
      content.forEach(user => {
        if (user.topUserId) {
          const parent = state.userMap[user.topUserId]
          if (parent) {
            // 이미 추가된 경우 중복 추가 방지
            if (!parent.subUserInfos.some(child => child.userId === user.userId)) {
              parent.subUserInfos.push(user)
            }
          }
        }
      })

      // 세팅된 userMap을 이용해서 state.partnerManageList 를 가공하기
      if (state.partnerManageList.length > 0) {
        // 데이터가 이미 있는경우
        const findAndInsert = partnerList => {
          partnerList.forEach(user => {
            const matchingUser = state.userMap[user.userId]

            if (matchingUser) {
              user.subUserInfos = matchingUser.subUserInfos.map(subUser => {
                // 기존 user.subUserInfos에서 해당 userId를 가진 요소 찾기
                const existingSubUser = user.subUserInfos.find(u => u.userId === subUser.userId)
                return {
                  ...subUser,
                  isOpened: existingSubUser ? existingSubUser.isOpened : subUser.isOpened ?? false,
                }
              })
            }

            if (user.subUserInfos.length > 0) {
              findAndInsert(user.subUserInfos)
            }
          })
        }
        findAndInsert(state.partnerManageList)
      } else {
        // 아무것도 없는 경우
        state.partnerManageList = content
      }

      state.apiLoading = false
    })
  },
})

export const { handleToggle, resetPartnerList, onClickSearchHandler } = partnerInfoSlice.actions

export default partnerInfoSlice.reducer
