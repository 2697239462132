import {
  AssetIncDecMethodTypeEnumsSelectOptions,
  MemberMoneyTypeEnumsSelectOptions,
  MoneyIncDecTypeEnumsSelectOptions,
} from '@/enums/MemberManage/MemberMoneyIncDecEnums'
import { SearchCardTitleWrap } from '@/shared/components/Card'
import { MemberIncDecSelect } from '@/shared/components/CustomSelect'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
  MemberMoneyIncDecFormButtonToolbar,
} from '@/shared/components/form/FormElements'
import { useDispatch, useSelector } from 'react-redux'
import FormField from '@/shared/components/form/FormField'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import Loading from '../../../../../shared/components/Loading'
import Error from '../../../../../shared/components/form/Error'
import {
  searchAssetIncDec,
  updateMemberAssetIncDecInfo,
  useSearchAssetIncDec,
  useSearchMemberAssetInfo,
} from '../../../../../utils/api/logManage/logApi'
import { decodeAccessToken } from '../../../../../utils/token'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import { UserAssetIncDecValidate } from '../../../../../utils/validate/memberManage/UserAssetIncDecValidate'
import MemberMoneyIncDecListData from '../../MemberMoneyIncDec/MemberMoneyIncDecListData'
import { fetchMemberInfo } from '../../../../../redux/memberInfoSlice'
import { MoneyAssetTypeEnumsSelectOptions } from '../../../../../enums/Log/moneyIncDecEnums'

const MemberHistoryMoneyIncDec = ({ memberId, onClickHandler }) => {
  const dispatch = useDispatch()
  const [moneyIncDecLoading, setMoneyIncDecLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority

  /**
   * 회원의 머니 증감 이력 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [moneyIncDecParams, setMoneyIncDecParams] = useState({
    userId: memberId,
    assetType: null,
    incDecType: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [content, setContent] = useState([])
  const [totalElement, setTotalElement] = useState(0)
  const [apiLoading, setApiLoading] = useState(false)

  const fetchSearchAssetIncDec = async () => {
    if (apiLoading) return
    setApiLoading(true)
    await searchAssetIncDec(moneyIncDecParams).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
      setApiLoading(false)
    })
  }
  useEffect(() => {
    fetchSearchAssetIncDec()
  }, [memberId])

  const [memberMoneyIncDecListData, setMemberMoneyIncDecListData] = useState(MemberMoneyIncDecListData(content))

  useEffect(() => {
    setMemberMoneyIncDecListData(MemberMoneyIncDecListData(content))
  }, [content])

  const [memberMoneyIncDecRows, setMemberMoneyIncDecRows] = useState(memberMoneyIncDecListData.tableRowsData)

  useEffect(() => {
    setMemberMoneyIncDecRows(memberMoneyIncDecListData.tableRowsData)
  }, [memberMoneyIncDecListData])

  // 머니/콤프 선택
  const [assetType, setAssetType] = useState(null)

  const assetTypeOption = MoneyAssetTypeEnumsSelectOptions()

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 종류
  const [searchMoneyIncDecType, setSearchMoneyIncDecType] = useState('')

  const [searchMoneyIncDecTypeOptionValue, setSearchMoneyIncDecTypeOptionValue] = useState(null)
  const searchMoneyIncDecTypeOption = MoneyIncDecTypeEnumsSelectOptions()

  const onSelectMoneyIncDecTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyIncDecTypeOptionValue(null)
      setSearchMoneyIncDecType(null)
      return
    }
    setSearchMoneyIncDecTypeOptionValue(option)
    setSearchMoneyIncDecType(option.value)
  }

  // 구분 - 포인트 , 머니 , 콤프
  const [searchMoneyType, setSearchMoneyType] = useState('')

  const [searchMoneyTypeOptionValue, setSearchMoneyTypeOptionValue] = useState(null)
  const searchMoneyTypeOption = MemberMoneyTypeEnumsSelectOptions()

  const onSelectTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyTypeOptionValue(null)
      setSearchMoneyType(null)
      return
    }
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyType(option.value)
  }

  // 처리일시 시작 + 끝
  const [searchMoneyIncDecStartDate, setSearchMoneyIncDecStartDate] = useState(null)
  const [searchMoneyIncDecEndDate, setSearchMoneyIncDecEndDate] = useState(null)

  const onSearchMoneyIncDecStartDateChangeHandler = date => {
    setSearchMoneyIncDecStartDate(date)
  }
  const onSearchMoneyIncDecEndDateChangeHandler = date => {
    setSearchMoneyIncDecEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page: 0,
      assetType: !searchMoneyType ? null : searchMoneyType,
      incDecType: !searchMoneyIncDecType ? null : searchMoneyIncDecType,
      startDate: !searchMoneyIncDecStartDate ? null : convertToKst(searchMoneyIncDecStartDate),
      endDate: !searchMoneyIncDecEndDate ? null : convertToKst(searchMoneyIncDecEndDate),
    }))
  }

  /**
   * 회원 머니증감 정보 조회
   * partnerId 있으면 매장 보유머니도 같이 조회해야 함
   */
  const [memberMoneyIncDecInfoParams, setMemberMoneyIncDecInfoParams] = useState({
    memberId,
  })

  const [userMoneyAmount, setUserMoneyAmount] = useState('0')
  const [userGameMoneyAmount, setUserGameMoneyAmount] = useState('0')
  const [userHoldemMoneyAmount, setUserHoldemMoneyAmount] = useState('0')

  const { memberMoneyIncDecInfoContent, fetchSearchMemberAssetInfo } =
    useSearchMemberAssetInfo(memberMoneyIncDecInfoParams)

  useEffect(() => {
    setUserMoneyAmount(memberMoneyIncDecInfoContent?.holdingMoney)
    setUserGameMoneyAmount(memberMoneyIncDecInfoContent?.holdingTotalGameMoney)
    setUserHoldemMoneyAmount(memberMoneyIncDecInfoContent?.holdingHoldemMoney)
  }, [memberMoneyIncDecInfoContent])

  // 증차감 선택
  const [moneyIncDecType, setMoneyIncDecType] = useState('')

  const moneyIncDecTypeOption = AssetIncDecMethodTypeEnumsSelectOptions()

  // 증차감 금액
  const [moneyIncDecAmount, setMoneyIncDecAmount] = useState('')
  const [applyPoint, setApplyPoint] = useState('0')

  const { holdingMoney, holdemMoneyAmount } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      holdemMoneyAmount: memberInfo.holdemMoneyAmount,
    }
  })

  // 적용하기
  const onSubmit = e => {
    if (moneyIncDecLoading) return
    const checkMoney = applyPoint?.toString().replaceAll(',', '')
    if (e.assetType.value === 'NORMAL_MONEY') {
      if (Number(checkMoney) > Number(holdingMoney)) {
        alert('내 보유머니를 다시 확인해주세요.')
        return
      }
    }

    if (e.assetType.value === 'HOLDEM_MONEY') {
      if (Number(checkMoney) > Number(holdemMoneyAmount)) {
        alert('내 홀덤머니를 다시 확인해주세요.')
        return
      }
    }

    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    const body = {
      memberId,
      assetType: e.assetType.value,
      incDecType: 'INC',
      assetIncDecAmount: checkMoney,
    }
    setMoneyIncDecLoading(true)
    updateMemberAssetIncDecInfo(body)
      .then(res => {
        setMoneyIncDecLoading(false)
        alert('적용 되었습니다.')
        fetchSearchMemberAssetInfo()
        fetchSearchAssetIncDec()
        dispatch(fetchMemberInfo())
        setAssetType(null)
        setApplyPoint(0)
      })
      .catch(error => {
        setMoneyIncDecLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'SYSTEM-1020') {
          alert(`${message}`)
        } else {
          alert('적용 실패하였습니다.')
        }
      })
  }

  const onClickPointHandler = name => {
    setApplyPoint(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyPoint('0')
  }

  return (
    <>
      {/* <h3 style={{ marginTop: '-10px', marginBottom: '5px', fontWeight: '500' }}>자산 회수</h3> */}
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
          <p>
            * 하위 유저의 <span style={{ color: 'red' }}>지갑머니</span>를 지급 할 수 있습니다.
          </p>
          <p>
            * 일반머니는 <span style={{ color: 'red' }}>내 지갑머니</span>기준으로 지급됩니다, 홀덤머니는{' '}
            <span style={{ color: 'red' }}>내 홀덤머니</span>기준으로 지급됩니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      {moneyIncDecLoading ? (
        <Loading />
      ) : (
        <Form
          onSubmit={onSubmit}
          validate={UserAssetIncDecValidate}
          initialValues={{
            memberId,
            memberMoneyAmount: userMoneyAmount?.toString().replace(commonReg2, ','),
            userGameMoneyAmount: userGameMoneyAmount?.toString().replace(commonReg2, ','),
            userHoldemMoneyAmount: userHoldemMoneyAmount?.toString().replace(commonReg2, ','),
            memberGameMoneyAmount: userGameMoneyAmount?.toString().replace(commonReg2, ','),
            moneyIncDecAmount,
            moneyIncDecType,
            holdingMoney: holdingMoney?.toString().replace(commonReg2, ','),
            holdemMoneyAmount: holdemMoneyAmount?.toString().replace(commonReg2, ','),
            applyPoint,
            assetType,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormGroup>
                <FormGroupLabel>내 지갑머니</FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="holdingMoney"
                    component={FormField}
                    type="text"
                    value={holdingMoney}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>내 홀덤머니</FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="holdemMoneyAmount"
                    component={FormField}
                    type="text"
                    value={holdemMoneyAmount}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>유저 ID(명)</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input style={{ border: 'solid 1px black' }} {...input} type="text" disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  {memberId} <br />
                  지갑머니
                </FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="memberMoneyAmount"
                    component={FormField}
                    type="text"
                    value={userMoneyAmount}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  {memberId} <br />
                  카지노머니
                </FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="userGameMoneyAmount"
                    component={FormField}
                    type="text"
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  {memberId} <br />
                  홀덤머니
                </FormGroupLabel>
                <FormGroupField>
                  <Field
                    style={{ border: 'solid 1px black' }}
                    name="userHoldemMoneyAmount"
                    component={FormField}
                    type="text"
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>일반/홀덤</FormGroupLabel>
                <FormGroupField>
                  <Field name="assetType">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <MemberIncDecSelect
                          {...input}
                          value={assetType}
                          onChange={option => {
                            setAssetType(option)
                          }}
                          options={assetTypeOption}
                          isClearable
                          placeholder="일반/홀덤 선택"
                          className="react-select"
                          classNamePrefix="react-select"
                        />
                        {meta.touched && meta.error && <Error error={meta.error} top />}
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>지급액</FormGroupLabel>
                <FormGroupField>
                  <Field name="applyPoint">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            style={{ border: 'solid 1px black' }}
                            type="text"
                            value={applyPoint}
                            onChange={e => {
                              const { value } = e.target
                              const onlyNumber = value.replace(/[^0-9]/g, '')
                              setApplyPoint(onlyNumber)
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                  <FormGroupIcon>원</FormGroupIcon>
                </FormGroupField>
              </FormGroup>
              <div style={{ width: '110%', marginLeft: '-130px', marginBottom: '10px' }}>
                <FormGroupField style={{ width: '100%', display: 'flex', gap: '10px' }}>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="1000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    1천
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="10000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    1만
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="50000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    5만
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="100000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    10만
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="500000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    50만
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="1000000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    100만
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="5000000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    500만
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    name="10000000"
                    onClick={e => {
                      onClickPointHandler(e.target.name)
                    }}
                  >
                    1000만
                  </Button>
                  <Button
                    style={{ background: 'lightgrey', color: '#000', fontWeight: '900' }}
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      onClearClickHandler()
                    }}
                  >
                    정정
                  </Button>
                </FormGroupField>
              </div>
              <MemberMoneyIncDecFormButtonToolbar>
                <Button variant="primary" type="submit" style={{ marginTop: '-10px' }}>
                  적용하기
                </Button>
              </MemberMoneyIncDecFormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      )}

      <h4 style={{ margin: '5px 0', fontWeight: '500' }}>자산 증감 내역</h4>
      <SearchContainer horizontal>
        {/* <SearchGroup>
          <SearchGroupLabel>종류</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchMoneyType"
              onChange={option => {
                onSelectMoneyIncDecTypeOptionHandler(option)
              }}
              value={searchMoneyIncDecTypeOptionValue}
              options={searchMoneyIncDecTypeOption}
              placeholder="종류"
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>구분</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchMoneyType"
              onChange={option => {
                onSelectTypeOptionHandler(option)
              }}
              value={searchMoneyTypeOptionValue}
              options={searchMoneyTypeOption}
              placeholder="구분"
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup> */}
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchMoneyIncDecStartDate}
                onChange={date => onSearchMoneyIncDecStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchMoneyIncDecEndDate}
                onChange={date => onSearchMoneyIncDecEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <ReactTableBase
        key="common"
        columns={memberMoneyIncDecListData.tableHeaderData}
        data={memberMoneyIncDecRows}
        tableConfig={tableConfig}
      />
    </>
  )
}

export default MemberHistoryMoneyIncDec

MemberHistoryMoneyIncDec.propTypes = {
  memberId: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}
