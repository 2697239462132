import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  CustomModalBody,
  CustomStyledModal,
  CustomModalTitle,
  CustomModalHeader,
  CustomModalCloseButton,
} from '@/shared/components/CustomModal'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { searchMember } from '@/utils/api/memberManage/memberManageApi'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { MemberModifyStatusEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import { decodeAccessToken } from '../../../utils/token'
import MemberManageMentTabs from '../MemberManageMentTabs'
import MemberListData from './MemberListData'
import MemberHistoryMoneyIncDec from './MemberInfo/MemberMoneyIncDec/MemberHistoryMoneyIncDec'
import MemberHistoryMoneyDec from './MemberInfo/MemberMoneyIncDec/MemberHistoryMoneyDec'

const MemberList = () => {
  /**
   * 회원 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)
  const [userAuthority, setUserAuthority] = useState(null)
  const [whatSearch, setWhatSearch] = useState(null)
  const [currentUserSearch, setCurrentUserSearch] = useState(false)
  const [currentGameUserSearch, setCurrentGameUserSearch] = useState(false)
  const [directUserSearch, setDirectUserSearch] = useState(false)
  // 사이트명
  const [searchStatus, setSearchStatus] = useState('')

  const [params, setParams] = useState({
    page,
    size,
    edgeName: null,
    searchStatus: null,
    whatSearch: null,
    currentUserSearch,
    currentGameUserSearch,
    authority: null,
    directUserSearch,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      whatSearch,
    }))
  }, [whatSearch])

  useEffect(() => {
    if (currentUserSearch) {
      setCurrentGameUserSearch(false)
    }
    setParams(prev => ({
      page: 0,
      size: 15,
      edgeName: null,
      searchStatus: null,
      whatSearch: null,
      memberId: null,
      friendUserId: null,
      topPartnerName: null,
      currentGameUserSearch: false,
      currentUserSearch,
      directUserSearch,
    }))
  }, [currentUserSearch])

  useEffect(() => {
    if (currentGameUserSearch) {
      setCurrentUserSearch(false)
    }
    setParams(prev => ({
      page: 0,
      size: 15,
      edgeName: null,
      searchStatus: null,
      whatSearch: null,
      memberId: null,
      friendUserId: null,
      topPartnerName: null,
      currentUserSearch: false,
      currentGameUserSearch,
      directUserSearch,
    }))
  }, [currentGameUserSearch])

  useEffect(() => {
    setParams(prev => ({
      page: 0,
      size: 15,
      edgeName: null,
      searchStatus: null,
      whatSearch: null,
      memberId: null,
      friendUserId: null,
      topPartnerName: null,
      currentUserSearch,
      currentGameUserSearch,
      directUserSearch,
    }))
  }, [directUserSearch])

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const [searchStatusToggle, setSearchStatusToggle] = useState(false)

  const onChangeSearchStatusHandler = status => {
    setSearchStatusOptionValue(status)
    setSearchStatusToggle(prev => !prev)
  }

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      searchStatus: searchStatusOptionValue,
    }))
  }, [searchStatusToggle])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)
  const fetchSearchMember = async () => {
    if (loading) return
    setLoading(true)
    await searchMember(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
    setLoading(false)
  }

  useEffect(() => {
    fetchSearchMember()
  }, [params])

  const [isModalShow, setModalShow] = useState(false)
  const onModalClickHandler = () => {
    setModalShow(prev => !prev)
    fetchSearchMember()
  }

  const [whatModalShow, setWhatModalShow] = useState(false)
  const [isModalUserId, setModalUserId] = useState('')

  const [memberListData, setMemberListData] = useState(
    MemberListData(content, setModalShow, setWhatModalShow, setModalUserId),
  )

  useEffect(() => {
    setMemberListData(MemberListData(content, setModalShow, setWhatModalShow, setModalUserId))
  }, [content])

  const [rows, setData] = useState(memberListData.tableRowsData)

  useEffect(() => {
    setData(memberListData.tableRowsData)
  }, [memberListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 추천인
  const [searchFriendUserId, setSearchFriendUserId] = useState('')

  const onSearchFriendUserIdHandler = e => {
    setSearchFriendUserId(e.target.value)
  }

  // 매장명
  const [searchTopPartnerName, setSearchTopPartnerName] = useState('')

  const onSearchTopPartnerNameHandler = e => {
    setSearchTopPartnerName(e.target.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      memberId: !searchMemberId ? null : searchMemberId,
      topPartnerName: !searchTopPartnerName ? null : searchTopPartnerName,
    }))
  }

  const [activeKey, setActiveKey] = useState('1')

  const currentUserSearchHandler = () => {
    setCurrentUserSearch(prev => !prev)
  }

  const currentGameUserSearchHandler = () => {
    setCurrentGameUserSearch(prev => !prev)
  }

  const directUserSearchHandler = () => {
    setDirectUserSearch(prev => !prev)
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card>
          <CardBody>
            <MemberManageMentTabs activeKey={activeKey} />
            <SearchStatusWrap>
              <AuthoritySearchButton
                userAuthority={userAuthority}
                onClickHandler={e => {
                  setUserAuthority(e.target.id)
                }}
              />
              <Button
                size="sm"
                active={searchStatusOptionValue === null}
                variant="outline-secondary"
                onClick={e => {
                  onChangeSearchStatusHandler(null)
                }}
                style={{ width: '60px' }}
              >
                전체
              </Button>
              {Object.keys(MemberModifyStatusEnums).map(status => {
                return (
                  <Button
                    id={status}
                    size="sm"
                    style={{ width: '60px' }}
                    active={searchStatusOptionValue === status}
                    variant="outline-secondary"
                    onClick={e => {
                      onChangeSearchStatusHandler(status)
                    }}
                  >
                    {MemberModifyStatusEnums[status]}
                  </Button>
                )
              })}
            </SearchStatusWrap>
            <SearchStatusWrap>
              <CustomButton
                type="button"
                onClick={e => {
                  if (whatSearch !== 'totalMoney') {
                    setWhatSearch('totalMoney')
                  } else {
                    setWhatSearch(null)
                  }
                }}
                active={whatSearch === 'totalMoney'}
              >
                보유머니 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalDeposit'}
                onClick={e => {
                  if (whatSearch !== 'totalDeposit') {
                    setWhatSearch('totalDeposit')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                총입금액 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalWithdrawal'}
                onClick={e => {
                  if (whatSearch !== 'totalWithdrawal') {
                    setWhatSearch('totalWithdrawal')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                총출금액 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalExchange'}
                onClick={e => {
                  if (whatSearch !== 'totalExchange') {
                    setWhatSearch('totalExchange')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                환수율 정렬(⬆)
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalExchangeDown'}
                onClick={e => {
                  if (whatSearch !== 'totalExchangeDown') {
                    setWhatSearch('totalExchangeDown')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                환수율 정렬(⬇️)
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'lastLogin'}
                onClick={e => {
                  if (whatSearch !== 'lastLogin') {
                    setWhatSearch('lastLogin')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                최근 로그인 순 정렬
              </CustomButton>
              <CustomButton2
                size="sm"
                active={currentUserSearch}
                style={{ marginLeft: '20px' }}
                variant="outline-secondary"
                onClick={e => {
                  currentUserSearchHandler()
                }}
              >
                현재 접속중인 유저
              </CustomButton2>
              <CustomButton2
                size="sm"
                active={currentGameUserSearch}
                variant="outline-secondary"
                onClick={e => {
                  currentGameUserSearchHandler()
                }}
              >
                카지노 접속중인 유저
              </CustomButton2>
              <CustomButton3
                size="sm"
                active={directUserSearch}
                variant="outline-secondary"
                onClick={e => {
                  directUserSearchHandler()
                }}
              >
                직영 회원&파트너만
              </CustomButton3>
            </SearchStatusWrap>

            <SearchContainer horizontal style={{ marginBottom: '10px' }}>
              <SearchGroup>
                <SearchGroupLabel>유저 ID/명/닉네임/번호</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchValue"
                      type="text"
                      placeholder="입력해주세요"
                      value={searchMemberId}
                      onChange={e => onSearchMemberIdHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>직영 파트너 ID / 명</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchValue"
                      type="text"
                      placeholder="검색 입력해주세요"
                      value={searchTopPartnerName}
                      onChange={e => onSearchTopPartnerNameHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>추천인 ID / 명</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchValue"
                      type="text"
                      placeholder="입력해주세요"
                      value={searchFriendUserId}
                      onChange={e => onSearchFriendUserIdHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchButtonWrap>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    onSearchClickHandler()
                  }}
                >
                  검색하기
                </Button>
              </SearchButtonWrap>
            </SearchContainer>
            <ReactTableBase columns={memberListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </CardBody>
          <CustomModal show={isModalShow} onHide={onModalClickHandler} $header>
            <CustomModalHeader>
              <CustomModalCloseButton
                className="lnr lnr-cross"
                aria-label="close-btn"
                type="button"
                onClick={onModalClickHandler}
              />
              {
                {
                  ASSET: (
                    <>
                      <CustomModalTitle>
                        {isModalUserId} <span style={{ color: 'red', fontWeight: '900' }}>자산 지급</span>
                      </CustomModalTitle>
                    </>
                  ),
                  ASSET_DEC: (
                    <>
                      <CustomModalTitle>
                        {isModalUserId} <span style={{ color: 'blue', fontWeight: '900' }}>자산 회수 </span>{' '}
                      </CustomModalTitle>
                    </>
                  ),
                }[whatModalShow]
              }
            </CustomModalHeader>
            <CustomModalBody>
              {
                {
                  ASSET: (
                    <>
                      <MemberHistoryMoneyIncDec memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                    </>
                  ),
                  ASSET_DEC: (
                    <>
                      <MemberHistoryMoneyDec memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                    </>
                  ),
                }[whatModalShow]
              }
            </CustomModalBody>
          </CustomModal>
        </Card>
      )}
    </>
  )
}

export default MemberList

const SearchStatusWrap = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`

const CustomModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 600px !important;
  }
`

const CustomButton = styled.button`
  border: none;
  color: ${props => (props.active ? '#fff' : '#000')} !important;
  background-color: ${props => (props.active ? '#454545' : 'lightgray')} !important;
`

const CustomButton2 = styled.button`
  border: none;
  color: ${props => (props.active ? '#fff' : '#fff')} !important;
  background-color: ${props => (props.active ? '#665e47' : '#d1b975')} !important;
`

const CustomButton3 = styled.button`
  border: none;
  color: ${props => (props.active ? '#fff' : '#fff')} !important;
  background-color: ${props => (props.active ? '#665e47' : '#edae07')} !important;
`
