import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import SidebarCategory from './SidebarCategory'
import SidebarLink, { SidebarLinkTitle } from './SidebarLink'

const SidebarContent = ({ onClick, collapse }) => {
  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={collapse}>
        <SidebarLink title="대시보드" icon="star" route="/statistics" onClick={onClick} />
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="유저 관리" icon="users" collapse={collapse}>
          <SidebarLink title="유저 조회(간략)" route="/user/management/member" onClick={onClick} />
          <SidebarLink title="유저 조회(세부)" route="/user/management/partner" onClick={onClick} />
          <SidebarLink title="유저 등록" route="/user/management/registration" onClick={onClick} />
        </SidebarCategory>
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="롤링&루징 통계" icon="chart-bars" collapse={collapse}>
          <SidebarLink title="정산 통계" route="/statistics/rolling" onClick={onClick} />
          <SidebarLink title="정산 통계(V2)" route="/statistics/rolling2" onClick={onClick} />
          <SidebarLink title="롤링 적립내역" route="/user/history/pointdeposit" onClick={onClick} />
          <SidebarLink title="루징 적립내역" route="/user/history/losing-pointdeposit" onClick={onClick} />
          <SidebarLink title="롤링/루징 전환신청" route="/partner/management/pointexchange" onClick={onClick} />
          <SidebarLink title="롤링/루징 전환내역" route="/user/history/pointexchange" onClick={onClick} />
        </SidebarCategory>
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="머니 내역" icon="history" collapse={collapse}>
          {/* <SidebarLink title="입금신청" route="/partner/moneydeposit" onClick={onClick} />
          <SidebarLink title="출금신청" route="/partner/moneywithdrawal" onClick={onClick} /> */}
          <SidebarLink title="입출금 내역" route="/management/log/moneydepowithdraw" onClick={onClick} />
          <SidebarLink title="환수율 내역" route="/user/history/moneyexchange-rate" onClick={onClick} />
          <SidebarLink title="머니 증감내역" route="/management/log/moneyinc" onClick={onClick} />
        </SidebarCategory>
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="배팅 내역" icon="pie-chart" collapse={collapse}>
          <SidebarLink title="스포츠 배팅내역" route="/management/log/sport-betting" onClick={onClick} />
          <SidebarLink title="카지노 배팅내역" route="/management/log/betting" onClick={onClick} />
          <SidebarLink title="미니게임 배팅내역" route="/management/log/minigame-betting" onClick={onClick} />
          <SidebarLink title="가상게임 배팅내역" route="/management/log/virtualgame-betting" onClick={onClick} />
          <SidebarCategory title="레볼루션 홀덤 내역" collapse={collapse}>
            <SidebarLink title="배팅내역" route="/management/log/holdem/revolution/betting-log" onClick={onClick} />
            <SidebarLink
              title="배팅 세부내역"
              route="/management/log/holdem/revolution/betting-detail-log"
              onClick={onClick}
            />
            <SidebarLink
              title="바아인/아웃 내역"
              route="/management/log/holdem/revolution/buyinout-log"
              onClick={onClick}
            />
          </SidebarCategory>
          {/* <SidebarLink title="토큰게임 배팅내역" route="/management/log/tokengame-betting" onClick={onClick} />
          <SidebarCategory title="와일드게임즈 내역" collapse={collapse}>
            <SidebarLink title="배팅내역" route="/management/log/holdem/wild-games/betting-log" onClick={onClick} />
            <SidebarLink
              title="배팅 세부내역"
              route="/management/log/holdem/wild-games/betting-detail-log"
              onClick={onClick}
            />
          </SidebarCategory> */}
        </SidebarCategory>
      </SidebarBlock>
    </SidebarContentWrap>
  )
}

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  collapse: PropTypes.bool,
}

SidebarContent.defaultProps = {
  collapse: false,
}

export default SidebarContent

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    // padding-top: 15px;

    ${props =>
      props.collapse &&
      `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid #e1e1e1;
  list-style-type: none;

  &:last-child {
    border: none;
  }

  // 사이드바 호버
  li a {
    &:hover {
      background-color: red;
    }
  }

  @media screen and (min-width: 576px) {
    ${props =>
      props.collapse &&
      `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: white;
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          left: 70px;
        }
  
        &:hover {
          background: #fafbfe;
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`

// endregion
